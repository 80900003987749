
"function" !== typeof Object.create && (Object.create = function(c) {
	function h() {}
	h.prototype = c;
	return new h
});
(function(c, h, k, p) {
	var n = {
		init: function(a, b) {
			var d = this;
			d.elem = b;
			d.$elem = c(b).find(".bwl_news_lists");
			this.options = c.extend({}, c.fn.bwlJqueryNewsTicker.config, a);
			1 == d.options.hide_ticker && c(b).remove();
			c(b).addClass("bnt_dn");
			var e = "",
				f = "",
				g = "",
				l = "",
				m = "";
			"red" == d.options.theme ? e = "red_" : "green" == d.options.theme ? e = "green_" : "blue" == d.options.theme ? e = "blue_" : "orange" == d.options.theme ? e = "orange_" : "pink" == d.options.theme ? e = "pink_" : "purple" == d.options.theme ? e = "purple_" : "olive" == d.options.theme ? e = "olive_" : "yellow" == d.options.theme ? e = "yellow_" : "gray" == d.options.theme ? e = "gray_" : "chocolate" == d.options.theme && (e = "chocolate_");
			"" != e && (f += e + "bwl_" + this.options.position + "_ticker_wrapper ", g += " " + e + "ticker_title", l += e + "ticker_header_toggle_wrapper ", m += e + "ticker_footer_toggle_wrapper ");
			c(b).attr("class", f + "bwl_" + this.options.position + "_ticker_wrapper");
			d.$elem.wrap('<div class="bwl_' + this.options.position + '_ticker_content"></div>');
			1 == this.options.title_show && d.$elem.before('<span class="ticker_title' + g + '">' + this.options.title + "</span>");
			1 == this.options.feed && d._bwl_get_feed_data(c(b));
			this.options.bwl_up_icon = '<i class="fa ' + d.options.bwl_up_icon + '"></i>';
			this.options.bwl_down_icon = '<i class="fa ' + d.options.bwl_down_icon + '"></i>';
			this.options.bwl_prev_icon = '<i class="fa ' + d.options.bwl_prev_icon + '"></i>';
			this.options.bwl_next_icon = '<i class="fa ' + d.options.bwl_next_icon + '"></i>';
			1 == this.options.btn_show && (d.$elem.after('<div class="ticker_btn_wrapper"><span class="bwl_next_btn">' + this.options.bwl_prev_icon + '</span><span class="bwl_prev_btn">' + this.options.bwl_next_icon + "</span></div>"), "header" == this.options.position ? c(b).after('<div class="' + l + 'ticker_header_toggle_wrapper"><span class="bwl_header_toggle_btn" data-display-status="0">' + this.options.bwl_up_icon + "</span></div>") : "footer" == this.options.position && c(b).after('<div class="' + m + 'ticker_footer_toggle_wrapper"><span class="bwl_footer_toggle_btn" data-display-status="0">' + this.options.bwl_down_icon + "</span></div>"));
			1 == d.options.title_arrow_show && (c(b).find(".ticker_title").append(c("<span/>", {
				"class": "bnt-" + d.options.title_arrow + "-right"
			})), c(b).find("span.bnt-" + d.options.title_arrow + "-right").css({
				top: (parseInt(d.options.height) - parseInt(c(".bnt-" + d.options.title_arrow + "-right").outerHeight())) / 2 + "px"
			}));
			d._bwl_custom_theme_settings(c(b));
			d._bwl_ticker_border_settings(c(b));
			e = d._get_animation_class();
			this.options.OutAnimation = e[0];
			this.options.inAnimation = e[1];
			d._bwl_set_timmer();
			this._bindEvent();
			this._init_bwl_ticker(c(b));
			if ("header" == this.options.position) c("span.bwl_header_toggle_btn").on("click", function() {
				d._bwl_toggle_ticker_bar(c(b), c(this), d.options.position, c(this).data("display-status"))
			});
			if ("footer" == this.options.position) c("span.bwl_footer_toggle_btn").on("click", function() {
				d._bwl_toggle_ticker_bar(c(b), c(this), d.options.position, c(this).data("display-status"))
			});
			c(b).on("click", "span.bwl_next_btn", function() {
				d._bwl_display_next_item()
			});
			c(b).on("click", "span.bwl_prev_btn", function() {
				d._bwl_display_prev_item()
			});
			d._bwl_ticker_rtl_settings(c(b))
		},
		_bindEvent: function() {
			var a = this;
			a.$elem.find("li").first().addClass(a.options.inAnimation);
			a.$elem.find("li a").each(function() {
				c(this).hover(function() {
					clearTimeout(a.bnt_timmer)
				}, function() {
					a._bwl_set_timmer()
				})
			})
		},
		_init_bwl_ticker: function(a) {
			var b = this;
			a.next(".bwl_display_btn");
			var d = a.outerHeight();
			if (c(".bwl_header_toggle_btn").length && "header" == b.options.position) {
				var e = c(".ticker_header_toggle_wrapper");
				c(".bwl_header_toggle_btn");
				c([]).add(a).add(e).addClass("bnt_dn");
				"undefined" != typeof b._bwl_read_cookie("bwl_header_ticker_status") && 0 == b._bwl_read_cookie("bwl_header_ticker_status") ? (e.css({
					right: "21px",
					top: "0px"
				}), e.slideDown("slow").find("span").html(b.options.bwl_down_icon).data("display-status", 0)) : (a.slideDown("slow", function() {
					e.slideDown("slow").find("span").html(b.options.bwl_up_icon).data("display-status", 1)
				}), e.css({
					right: "21px",
					top: d + "px"
				}));
				1 == b.options.rtl && e.css({
					left: "21px"
				})
			} else if (c(".bwl_footer_toggle_btn").length && "footer" == b.options.position) {
				var f = c(".ticker_footer_toggle_wrapper");
				c(".bwl_footer_toggle_btn");
				c([]).add(a).add(f).addClass("bnt_dn");
				"undefined" != typeof b._bwl_read_cookie("bwl_footer_ticker_status") && 0 == b._bwl_read_cookie("bwl_footer_ticker_status") ? (f.css({
					bottom: "0px"
				}), f.slideDown("slow").find("span").html(b.options.bwl_up_icon).data("display-status", 0)) : (a.slideDown("slow", function() {
					f.slideDown("slow").find("span").html(b.options.bwl_down_icon).data("display-status", 1)
				}), f.css({
					right: "21px",
					bottom: d + "px"
				}));
				1 == b.options.rtl && f.css({
					left: "21px"
				})
			}
			return !1
		},
		_bwl_custom_theme_settings: function(a) {
			if (48 != this.options.height) {
				c([]).add(a).add(a.find(".ticker_title")).add(a.find(".bwl_news_lists li")).css({
					height: this.options.height + "px",
					"line-height": this.options.height + "px"
				});
				var b = a.find(".bwl_prev_btn").outerHeight();
				a.find(".ticker_btn_wrapper").css({
					top: (parseInt(this.options.height) - parseInt(b)) / 2 + "px"
				})
			}
			"custom" == this.options.theme && (a.find(".ticker_title").css({
				"background-color": this.options.title_bg,
				color: this.options.title_color
			}), a.find(".bwl_prev_btn, .bwl_next_btn").css({
				"border-color": this.options.title_bg,
				"background-color": this.options.title_bg,
				color: this.options.title_color
			}), c(".bwl_" + this.options.position + "_toggle_btn").css({
				"background-color": this.options.title_bg,
				color: this.options.title_color
			}), c(".bwl_" + this.options.position + "_ticker_wrapper").css({
				"background-color": this.options.ticker_content_bg
			}))
		},
		_bwl_ticker_border_settings: function(a) {
			0 == this.options.border_status && a.css({
				"border-width": "0px"
			});
			1 == this.options.custom_border && ("header" == this.options.position ? a.css({
				"border-bottom": this.options.border_size + "px " + this.options.border_style + " " + this.options.border_color
			}) : "footer" == this.options.position ? a.css({
				"border-top": this.options.border_size + "px " + this.options.border_style + " " + this.options.border_color
			}) : a.css({
				border: this.options.border_size + "px " + this.options.border_style + " " + this.options.border_color
			}))
		},
		_bwl_toggle_ticker_bar: function(a, b, d, e) {
			var f = this,
				g;
			1 == e ? (b.data("display-status", 0).hide(), c([]).add(b).add(a).slideUp(function() {
				c(".ticker_" + d + "_toggle_wrapper").removeClass("bnt_dn").slideDown("slow", function() {
					"header" == d ? (c(this).css({
						top: "0px"
					}), g = f.options.bwl_down_icon) : "footer" == d && (c(this).css({
						bottom: "0px"
					}), g = f.options.bwl_up_icon);
					b.html(g).slideDown(700)
				})
			}), f._bwl_create_cookie("bwl_" + d + "_ticker_status", 0, 30)) : (b.data("display-status", 1).hide(1), c([]).add(b).add(a).slideDown(function() {
				c(".ticker_" + d + "_toggle_wrapper").removeClass("bnt_dn").slideDown(700, function() {
					"header" == d ? (c(this).css({
						top: a.outerHeight()
					}), g = f.options.bwl_up_icon) : "footer" == d && (c(this).css({
						bottom: a.outerHeight()
					}), g = f.options.bwl_down_icon);
					b.html(g).slideDown(1E3)
				})
			}), f._bwl_create_cookie("bwl_" + d + "_ticker_status"))
		},
		_bwl_get_feed_data: function(a) {
			var b = this,
				d = a.find("div.bwl_" + b.options.position + "_ticker_content");
			d.find("ul.bwl_news_lists").html("").html('<li style="text-align: center;"><img src="images/' + b.options.feed_load_icon + '" alt="Loading......"/></li>');
			c.when(b._ajax_parse_feeddata(b.options.feed_limit, b.options.feed_url)).done(function(a) {
				var e = "";
				try {
					c.each(a, function(a, d) {
						var c = "",
							f = d.link;
						1 == b.options.feed_link_new_window && (c += ' target="_blank"');
						"" != b.options.envato_user && (f += "?ref=" + b.options.envato_user);
						e += '<li><div class="itemTitle"><a href="' + f + '" ' + c + ">" + d.title + "</a></div>"
					}), d.find("ul.bwl_news_lists").html("").html(e), b._bindEvent()
				} catch (g) {
					e += '<div class="bnt_nothing_found">Nothing Found!</div>', d.html("").html(e)
				}
			})
		},
		_ajax_parse_feeddata: function(a, b) {
			return c.ajax({
				method: "POST",
				url: "rss_parser.php",
				data: {
					limit: a,
					location: b
				},
				dataType: "JSON"
			})
		},
		_bwl_display_next_item: function() {
			var a = this,
				b = a.$elem.height();
			clearTimeout(a.bnt_timmer);
			a.$elem.find("li").first().addClass(a.options.OutAnimation).delay(300).queue(function() {
				c(this).removeAttr("class").appendTo(a.$elem).dequeue();
				a.$elem.find("li").first().addClass(a.options.inAnimation).attr("height", b + "px");
				a._bwl_set_timmer()
			})
		},
		_bwl_display_prev_item: function() {
			var a = this,
				b = a.$elem.height();
			clearTimeout(a.bnt_timmer);
			a.$elem.find("li").last().addClass(a.options.OutAnimation).delay(300).queue(function() {
				c(this).removeAttr("class").prependTo(a.$elem).dequeue();
				a.$elem.find("li").removeAttr("class");
				a.$elem.find("li").first().addClass(a.options.inAnimation).attr("height", b + "px");
				a._bwl_set_timmer()
			})
		},
		_bwl_set_timmer: function() {
			var a = this;
			a.bnt_timmer = setTimeout(function() {
				a._bwl_show_ticker_next_item()
			}, a.options.interval)
		},
		_get_animation_class: function() {
			var a = "animated ",
				b = "animated ";
			"flash" == this.options.animation ? (b += "fadeOut", a += "flash") : "shake" == this.options.animation ? (b += "fadeOut", a += "shake") : "tada" == this.options.animation ? (b += "tada", a += "tada") : "swing" == this.options.animation ? (b += "swing", a += "swing") : "wobble" == this.options.animation ? (b += "wobble", a += "wobble") : "pulse" == this.options.animation ? (b += "pulse", a += "pulse") : "flipx" == this.options.animation ? (b += "flipOutX", a += "flipInX") : "faderight" == this.options.animation ? (b += "fadeOutRight", a += "fadeInLeft") : "fadeleft" == this.options.animation ? (b += "fadeOutLeft", a += "fadeInRight") : "slide" == this.options.animation ? (b += "slideOutLeft", a += "slideInRight") : "slideup" == this.options.animation ? (b += "slideOutUp", a += "slideInDown") : "bounce" == this.options.animation ? (b += "bounceOu", a += "bounceIn") : "lightspeed" == this.options.animation ? (b += "lightSpeedOut", a += "lightSpeedIn") : "roll" == this.options.animation ? (b += "rollOut", a += "rollIn") : "rotate" == this.options.animation ? (b += "rotateOut", a += "rotateIn") : (b += "fadeOut", a += "fadeIn");
			return [b, a]
		},
		_bwl_show_ticker_next_item: function() {
			var a = this,
				b = a.$elem.height();
			a.$elem.find("li").first().addClass(a.options.OutAnimation).delay(1E3).queue(function() {
				c(this).removeAttr("class").appendTo(a.$elem).dequeue();
				a.$elem.find("li").first().addClass(a.options.inAnimation).attr("height", b + "px")
			});
			a._bwl_set_timmer()
		},
		_bwl_ticker_rtl_settings: function(a) {
			1 == this.options.rtl ? (a.find(".ticker_title").css({
				"float": "right",
				"margin-right": "0px",
				"margin-left": "5px"
			}), a.find("ul.bwl_news_lists li").css({
				"text-align": "right",
				"float": "right"
			}), a.find("div.ticker_btn_wrapper").css({
				left: "5px"
			}), "inline" == this.options.position && (0 == this.options.custom_border && (this.options.border_size = 1), a.css({
				"border-left": this.options.border_size + "px " + this.options.border_style + " " + this.options.border_color
			}))) : (a.find("div.ticker_btn_wrapper").css({
				right: "5px"
			}), "inline" == this.options.position && 0 == this.options.title_show ? (0 == this.options.custom_border && (this.options.border_size = 1), a.css({
				"border-left": this.options.border_size + "px " + this.options.border_style + " " + this.options.border_color
			})) : a.css({
				"border-left": "0px " + this.options.border_style + " " + this.options.border_color
			}))
		},
		_bwl_create_cookie: function(a, b, d) {
			if (d) {
				var c = new Date;
				c.setTime(c.getTime() + 864E5 * d);
				d = "; expires=" + c.toGMTString()
			} else d = "";
			k.cookie = a + "=" + b + d + "; path=/"
		},
		_bwl_read_cookie: function(a) {
			a += "=";
			for (var b = k.cookie.split(";"), d = 0; d < b.length; d++) {
				for (var c = b[d];
				     " " == c.charAt(0);) c = c.substring(1, c.length);
				if (0 == c.indexOf(a)) return c.substring(a.length, c.length)
			}
			return null
		},
		_bwl_erase_cookie: function(a) {
			this._bwl_create_cookie(a, "", -1)
		}
	};
	c.fn.bwlJqueryNewsTicker = function(a) {
		return this.each(function() {
			Object.create(n).init(a, this)
		})
	};
	c.fn.bwlJqueryNewsTicker.config = {
		position: "header",
		hide_ticker: !1,
		interval: 7E3,
		animation: "fade",
		height: "48",
		title: "Breaking News",
		title_show: !0,
		title_arrow_show: !0,
		title_arrow: "triangle",
		title_bg: "#2c2c2c",
		title_color: "#FFFFFF",
		ticker_content_bg: "#FFFFFF",
		ticker_content_color: "#2c2c2c",
		border_status: !0,
		custom_border: !1,
		border_size: 3,
		border_color: "#2c2c2c",
		border_style: "solid",
		btn_show: !0,
		bwl_up_icon: "fa-arrow-up",
		bwl_down_icon: "fa-arrow-down",
		bwl_prev_icon: "fa-arrow-right",
		bwl_next_icon: "fa-arrow-left",
		rtl: !1,
		feed: !1,
		feed_url: "http://codecanyon.net/feeds/new-codecanyon-items.atom",
		feed_location: "header",
		feed_limit: 5,
		feed_link_new_window: !0,
		feed_load_icon: "bnt_loader.gif",
		envato_user: "",
		theme: "black"
	}
})(jQuery, window, document);

if( jQuery('ul.bwl_news_lists li').length > 1 ) {
	jQuery("#news_feed_ticker").bwlJqueryNewsTicker({
		interval: 6000,
		position: 'inline',
		animation: "fade",
		title: "",
		theme: 'red',
		btn_show: 'false'
	});
}